<template>
  <section class="pb-4 container assessoria__content">
    <v-row class="d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="8"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card container">
          <Card
            title="Assessoria Técnica aos Atingidos e Atingidas de Mariana"
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                A Assessoria Técnica Independente (ATI) aos Atingidos e
                Atingidas pelo rompimento da Barragem do Fundão em Mariana-MG é
                realizada pela
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{
                    name: 'sobre_caritas_regional',
                  }"
                  >Cáritas Brasileira Regional Minas Gerais</router-link
                >
                em três frentes de trabalho: Assessoria Técnica, Cadastro e
                Assessoria Jurídica. O projeto do Cadastro, que originou a
                proposta do “<router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'projeto' }"
                  >Mariana Território Atingido</router-link
                >” e embasa toda a metodologia do presente site, está mais
                detidamente descrito
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_cadastro' }"
                  >aqui</router-link
                >
                e encerrou as atividades em abril de 2022. Segue uma breve
                descrição a respeito dos dois demais eixos de atuação da Cáritas
                MG no território.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
    </v-row>

    <v-row
      class="section__container_card container d-flex justify-center align-center mx-auto"
    >
      <v-col cols="12" sm="4">
        <div>
          <router-link :to="{ name: 'sobre_assessoria_tecnica' }">
            <v-card class="card-hover assessoria_card" width="95%">
              <img src="../../assets/assessoria_tecnica.png" alt="" />
            </v-card>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div>
          <router-link :to="{ name: 'sobre_assessoria_juridica' }">
            <v-card class="card-hover assessoria_card" width="95%">
              <img src="../../assets/assessoria_juridica.png" alt="" />
            </v-card>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div>
          <router-link :to="{ name: 'sobre_cadastro' }">
            <v-card class="card-hover assessoria_card_cad" width="95%">
              <img
                max-width="100px"
                src="../../assets/cad_cadastro.png"
                alt=""
              />
            </v-card>
          </router-link>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";

export default {
  components: { Card },
};
</script>

<style scoped>
.card-hover:hover {
  border: 4px solid #c92f05;
}

.section__container_card {
  max-width: 40em;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.assessoria_card {
  height: 8em;
  margin: auto;
  padding: 2em;
  position: relative;
}

.assessoria_card img {
  position: absolute;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria_card_cad {
  height: 8em;
  margin: auto;
  padding: 2em;
  position: relative;
}

.assessoria_card_cad img {
  position: absolute;
  width: 25%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.assessoria_card_cad p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7em;
}

.assessoria__items p {
  text-align: center;
  font-size: 0.6em;
  max-width: 80%;
}
</style>
